<template>
  <div class="w-full">
    <component :is="appropriateComponent" v-if="appropriateComponent" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Wallet",

  metaInfo: {
    title: "Wallet",
  },

  components: {
    StreamerWallet: () => import("@/views/streamer/StreamerWallet"),
    OrganizationWallet: () => import("@/views/organization/OrganizationWallet"),
  },

  computed: {
    ...mapGetters(["getUserInfo", "getOrganization"]),

    appropriateComponent() {
      if (!this.getUserInfo?.type) {
        return null;
      }

      if (this.getUserInfo?.type === "streamer") {
        return "StreamerWallet";
      }

      return "OrganizationWallet";
    },
  },

  created() {
    if (!this.getUserInfo?.type || this.getOrganization?._id === "6104316858d6180c099272b9") {
      this.$router.push("/");
    }
  },
};
</script>
